import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import BlogPosts from './BlogPosts';
import BlogPost from '../components/BlogPost';
import '../pages/BlogPage.css';

function BlogPage() {

  const { postId } = useParams();
  const post = BlogPosts.find(post => post.id === parseInt(postId));

  return (
    <div className='blog-fullpage'>
      <head>
        <meta name="theme-color" content="#1c1c1c"/>
      </head>
      <div className='blog-block1'>
        <Navbar></Navbar>
      </div>
      <div className='blog-block2'>
        <div className="posts">
          {BlogPosts.map(post => (
            <Link className="post-box" to={`/blog/${post.id}`}>
              <div key={post.id}>
                <Link className="post-title" to={`/blog/${post.id}`}>
                  {post.title}
                </Link>
                <p className='post-preview'>{post.preview}</p>
                <div className='post-imgcase'>
                  <img class="post-thumbnail" src={post.thumbnail} alt={post.title} />
                </div>
              </div>
            </Link>
          ))}
        </div>
        {post && <BlogPost post={post}/>}
      </div>
    </div>
  );
}

export default BlogPage;
