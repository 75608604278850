import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ExperPosts from './ExperPosts';
import ExperPost from '../components/ExperPost';
import '../pages/ExperiencePage.css';

function ExperiencePage() {
    const { postId } = useParams();
    const post = ExperPosts.find(post => post.id === parseInt(postId));
  
    return (
      <div className='exper-fullpage'>
        <head>
          <meta name="theme-color" content="#1c1c1c"/>
        </head>
        <div className='exper-block1'>
          <Navbar></Navbar>
        </div>
        <div className='exper-block2'>
          <div className="posts">
            {ExperPosts.map(post => (
              <Link className="post-box" to={`/experience/${post.id}`}>
                <div key={post.id}>
                  <Link className="post-title" to={`/experience/${post.id}`}>
                    {post.title}
                  </Link>
                  <p className='post-preview'>{post.preview}</p>
                  <div className='post-imgcase'>
                    <img class="post-thumbnail" src={post.thumbnail} alt={post.title} />
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {post && <ExperPost post={post}/>}
        </div>
      </div>
    );
}

export default ExperiencePage