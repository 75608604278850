import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from './Navbar';
import ProjPosts from '../pages/ProjPosts';
import '../components/ProjPost.css';

const ProjPost = () => {
  const { id } = useParams(); // Use "id" instead of "postId"

  const post = ProjPosts.find(post => post.id === parseInt(id));

  return (
    <div className='proj-fullpage'>
      <head>
        <meta name="theme-color" content="#1c1c1c"/>
      </head>
      <div className='projpost-block1'>
        <Navbar></Navbar>
      </div>
      <div className='projpost-block2'>
        <Link to="/projects" className='backlink'>← Back to Projects</Link>
        <div className="modal-content">
          <h2 className='posttitle'>{post.title}</h2>
          <p>{post.content}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjPost;
