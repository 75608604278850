import tn_proj1 from '../assets/projects/tn_proj1.png'
import React from "react";

const ProjPosts = [
  {    
    id: 5,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_proj1,
    content: 
    <div className='projpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 4,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_proj1,
    content: 
    <div className='projpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 3,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_proj1,
    content: 
    <div className='projpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 2,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_proj1,
    content: 
    <div className='projpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 1,
    title: 'UNO Card Game',
    preview: 'May 2022 - ',
    thumbnail: tn_proj1,
    content: 
    <div className='projpost-fullpage'>
      <h2>Built in C for Introduction to Computer Programming class</h2>
      <a href='https://github.com/evanwmart/UNOFinalProject'>Link to Github repository</a>
      <p className='projpost-paragraph'>This project was for a class on programming in C. Looking back, there are several things that are not very great about this code. But it was a great effort in making a project with a partner, and getting familar with some lower level concepts (like memory allocation and pointers) </p>
    </div>
  },

];

export default ProjPosts;