import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className='navbar'>
      <ul className='nav-links'>
        <li><Link to="/" className='nav-home'>Home</Link></li>
        <li><Link to="/about" className='nav-about'>About Me</Link></li>
        <li><Link to="/experience" className='nav-experience'>Experience</Link></li>
        <li><Link to="/projects" className='nav-projects'>Projects</Link></li>
        <li><Link to="/blog" className='nav-blog'>Blog</Link></li>
        <li><Link to="/resume" className='nav-resume'>Resume</Link></li>
      </ul>
    </div>
  );
};

export default Navbar;
