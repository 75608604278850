import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ProjPosts from './ProjPosts';
import ProjPost from '../components/ProjPost';
import '../pages/ProjectsPage.css';

function ProjectsPage() {
    const { postId } = useParams();
    const post = ProjPosts.find(post => post.id === parseInt(postId));
  
    return (
      <div className='proj-fullpage'>
        <head>
          <meta name="theme-color" content="#1c1c1c"/>
        </head>
        <div className='proj-block1'>
          <Navbar></Navbar>
        </div>
        <div className='proj-block2'>
          <div className="posts">
            {ProjPosts.map(post => (
              <Link className="post-box" to={`/projects/${post.id}`}>
                <div key={post.id}>
                  <Link className="post-title" to={`/projects/${post.id}`}>
                    {post.title}
                  </Link>
                  <p className='post-preview'>{post.preview}</p>
                  <div className='post-imgcase'>
                    <img class="post-thumbnail" src={post.thumbnail} alt={post.title} />
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {post && <ProjPost post={post}/>}
        </div>
      </div>
    );
  }

export default ProjectsPage