import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from './Navbar';
import BlogPosts from '../pages/BlogPosts';
import '../components/BlogPost.css'

const BlogPost = () => {
  const { id } = useParams(); // Use "id" instead of "postId"

  const post = BlogPosts.find(post => post.id === parseInt(id));

  return (
    <div className='blog-fullpage'>
      <head>
        <meta name="theme-color" content="#1c1c1c"/>
      </head>
      <div className='blogpost-block1'>
        <Navbar></Navbar>
      </div>
      <div className='blogpost-block2'>
        <Link to="/blog" className='backlink'>← Back to Blog</Link>
        <div className="modal-content">
          <h2 className='posttitle'>{post.title}</h2>
          <p>{post.content}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
