import tn_blog1 from '../assets/blog/tn_blog1.png'
import React from "react";

const BlogPosts = [
  {
    id: 1,
    title: 'The Controversial Backbone of Modern Technology',
    preview: '15 Sep 2023',
    thumbnail: tn_blog1,
    content: 
    <div className='blogpost-fullpage'>
      <h3>15 Sep 2023</h3>
    </div>
  },

];

export default BlogPosts;