import React from 'react'
import Navbar from '../components/Navbar'
import '../pages/Resume.css'


function ResumePage () {
    return (
    <div className='resume-fullpage'>
      <head>
        <meta name="theme-color" content="#1c1c1c"/>
      </head>
      <div className='resume-block1'>
        <Navbar></Navbar>
      </div>
      <div className='resume-block2'>
            <div className='resume-container'>
                <a href="Resume.pdf" class="resume-download" download>Download Resume</a>
                <img src={require('./Resume-1.png')}alt="Evan Martin Resume" className='resume-resume'></img>
                <h3 className='resume-subtitle'>Certificates</h3>
                <p className='resume-paragraph'>PACT Online Penetration Testing Certification</p>
                <img src={require('../assets/pact-pt-cert.png')}alt="Evan Martin Resume" className='resume-cert-pt'></img>
                <p className='resume-paragraph'>PACT Online Machine Learning Certification</p>
                <img src={require('../assets/pact-ml-cert.png')}alt="Evan Martin Resume" className='resume-cert-ml'></img>
            </div>
        </div>
    </div>
    )
}

export default ResumePage