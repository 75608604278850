import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import AboutPage from './pages/AboutPage';

import ExperiencePage from './pages/ExperiencePage';
import ExperPost from './components/ExperPost';

import ProjectsPage from './pages/ProjectsPage';
import ProjPost from './components/ProjPost';

import BlogPage from './pages/BlogPage';
import BlogPost from './components/BlogPost';

import ResumePage from './pages/ResumePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/experience" element={<ExperiencePage />} />
        <Route path="/experience/:id" element={<ExperPost />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/:id" element={<ProjPost />} />
        <Route path="/blog/*" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/resume" element={<ResumePage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
