import React from 'react'
import Navbar from '../components/Navbar'
import '../pages/About.css'

function AboutPage() {
    return (
        <div className='about-fullpage'>
            <head>
                <meta name="theme-color" content="#1c1c1c"/>
            </head>
            <div className='about-block1'>
                <Navbar></Navbar>
            </div>
            <div className='about-block2'>
                <h2 className='about-title'>About Me</h2>
                <p className='about-paragraph'>
                    Hello, my name is Evan and I am a Senior in Software Engineering at the University of Arizona. 
                    I am an American Australian who is interested in developing innovative technology to provide a positive impact. Whether it be through increasing the quality of service, improving security, or developing new software, I strive to be a beneficial member to any technical team.
                    <br />
                    <br />
                    I grew up in Canberra, Australia and have spent time living in Philadelphia (PA), Redwood City (CA), and Tucson (AZ). Throughout my time in these locations I have found numerous topics of interest. These include health and fitness, art and visual creativity, and the development of technical processes. I have spent much of my younger schooling and education in art studios painting and expressing creative practices which was a highlight for myself in earning my International Baccalaureate Diploma. The most significant and more recent topics I have been involved with have been that of innovative technical products.
                    Feel free to browse through my other pages for more details regarding my work on these areas.
                    <br />
                    <br />
                    If you wish to get in contact with me I recommend using one of the three media below. 
                    <br />
                    <br />
                    Thank you for visiting my webpage!
                    <br />
                    <br />
                </p>
            </div>
            <div className='about-block3'>
                <ul className='about-links'>
                <li><a href="https://github.com/evanwmart" class="button-github">Github</a></li>
                <li><a href="https://www.linkedin.com/in/evanwmart/" class="button-linkedin">LinkedIn</a></li>
                <li><a href="mailto:evanwmart@gmail.com" class="button-email">Email</a></li>
                </ul>
            </div>
        </div>
    )
}

export default AboutPage