import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import './fonts/Aquarius/Aquarius-Bold.ttf';
import './fonts/Aquarius/Aquarius-Regular.ttf';
import './fonts/Aquarius/Aquarius-RegularMono.ttf';
import './fonts/Aquarius/Aquarius-RegularSmallCaps.ttf';

function App() {
  return (
    <div className='home-fullpage'>
      <head>
        <meta name="theme-color" content="#1c1c1c"/>
        <link rel="icon" href='./public/favicon.ico' /> 
      </head>
      <div className='home-block1'>
        <Navbar></Navbar>
      </div>
      <div className='home-block2'>
        <body>
          <p className='home-title'>Evan Martin</p>
          <p className='home-subtitle'>B.S. Software Engineering</p>
          <p className='home-paragraph'>Welcome to my webpage!</p>
        </body>
      </div>
      <div className='home-block3'>
        <ul className='home-links'>
          <li><a href="https://github.com/evanwmart" class="button-github">Github</a></li>
          <li><a href="https://www.linkedin.com/in/evanwmart/" class="button-linkedin">LinkedIn</a></li>
          <li><a href="mailto:evanwmart@gmail.com" class="button-email">Email</a></li>
        </ul>
      </div>
    </div>
  );
}

export default App;
