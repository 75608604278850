import tn_exper1 from '../assets/experience/tn_exper1.png'
import React from "react";

const experPosts = [
  {
    id: 4,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_exper1,
    content: 
    <div className='experpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 3,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_exper1,
    content: 
    <div className='experpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 2,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_exper1,
    content: 
    <div className='experpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },
  {
    id: 1,
    title: 'Introduction to React Hooks',
    preview: 'Learn the basics of React Hooks...',
    thumbnail: tn_exper1,
    content: 
    <div className='experpost-fullpage'>
      <h3>Hello</h3>
    </div>
  },

];

export default experPosts;